import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PageHeader from "../../components/pageheader"
import Padding from "../../components/padding"
import styled from "styled-components"
import Layout from "../../components/layout"
import Stappen from "../../components/stappen"
import Stappen2 from "../../components/stappen2"
import SEO from "../../components/seo"
import { device } from "../../queries"
import Stappenplan from "./stappenplan-dataverwijdering.json"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Link from '@material-ui/core/Link'
import Img from "gatsby-image"


const Margin = styled.div`
  height: 60px;
`

const Styledcrumbs = styled(Breadcrumbs)`
    .MuiBreadcrumbs-separator{
        font-weight: 600;
    }
`
const Crumblink = styled(Link)`
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
    color: var(--blue-primary);
    transition: all 0.25s cubic-bezier(.44,0,.17,1);
    &:hover{
        text-decoration: none;
        color: var(--blue-primary-h); 
    }
}
    &[aria-current="page"]{
        color: rgba(100,100,100);
        cursor: text;
        
        &:hover{
          

        }
    }
`

const Maincontent = styled.div`
    margin: 0 auto;
    max-width: 1200px;
    padding-top: 60px;
`

const Paragraph = styled.p`
    padding: 10px 0;
    @media ${device.tablet}{
      br{
      display: none;
    }
    }
`


const Subheader = styled.h2`
    font-weight: 700;
    font-size: 20px;
    color: var(--green-text);
    padding-top: 20px;
`

const Containerpad = styled.div`
`
const Vertpad = styled.div`
    padding-bottom: 50px;
    @media ${device.laptopL}{
        padding: 0 20px 60px 20px;
    }

    @media ${device.mobileL}{
        padding: 0 15px 60px 15px;
    }
`


const Flexdivider = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    &>*{
        width: 48%;
        @media ${device.tablet}{
        width: 100%;
        padding-bottom: 60px;
        &:last-of-type{
            padding-bottom: 0;

        }
        }

    }
    @media ${device.tablet}{
        flex-direction: column;
    }

`

const Checkgrid = styled.div`
    display: inline-grid;
    width: 100%;
    grid-template-columns: 50% 50%;
    grid-template-rows: ... | ... | ... |;
    gap: 15px 20px;
    padding: 10px 0;
    padding-top: 30px;
    @media ${device.tablet}{
        grid-template-columns: 100%;
    }


`
const Griditem = styled.div`
    display: flex;
    align-items: start;
    p{
        padding: 4px 10px;
    }

`

const Icoon = styled(FontAwesomeIcon)`
    font-size: 25px;
    display: inline-block;
    color: var(--green-primary);
`

function handleClick(event) {
    console.info("You clicked a breadcrumb.")
}

const Headerimage = ({something, className}) => {
    const data = useStaticQuery(graphql`
      query {
        placeholderImage: file(relativePath: { eq: "diensticonen/datavernietiging.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        anotherImage: file(relativePath: { eq: "datavernietiging/d-logistiek.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 2000 maxHeight: 200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        softdes: file(relativePath: { eq: "datavernietiging/softwarematige_dataverwijdering.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600 maxHeight: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mechdes: file(relativePath: { eq: "datavernietiging/mechanische_dataverwijdering.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 600 maxHeight: 200) {
                ...GatsbyImageSharpFluid
              }
            }
        }
      }
    `)
    const image = [<Img className={className} fluid={data.placeholderImage.childImageSharp.fluid} />,<Img className={className} fluid={data.anotherImage.childImageSharp.fluid}/>, <Img className={className} fluid={data.softdes.childImageSharp.fluid} />, <Img className={className} fluid={data.mechdes.childImageSharp.fluid} />]
    return image[something];
  }



const Datavernietiging = () => {
    var [layout, setLayout] = React.useState(0);

    

    return<Layout>
    <SEO title="Datavernietiging" />
    <PageHeader title="Datavernietiging" bannerimage={<Headerimage something="1" />}>
        <Styledcrumbs aria-label="breadcrumb">
        <Crumblink href="/" onClick={React.setitem}>
            Home
          </Crumblink>
          <Crumblink href="/diensten" onClick={handleClick}>
            Diensten
          </Crumblink>
          <Crumblink aria-current="page" href="/diensten/Dataverwijdering/" onClick={handleClick}>
            Datavernietiging
          </Crumblink>
        </Styledcrumbs>
    </PageHeader>
        <Maincontent>
            <Containerpad>
                <Vertpad>
                    <Subheader>Gegevensvernietiging: discreet, veilig en betrouwbaar!</Subheader>
                    <Paragraph>
                    U wilt absoluut voorkomen dat vertrouwelijke gegevens in verkeerde handen vallen.<br/> Daarom garanderen wij dat al uw gegevens en digitale datadragers van harde schijven tot smartphones en vele andere media, professioneel en veilig worden vernietigd.<br/> Zo vernietigen wij uw bedrijfsrisico.
                    </Paragraph>
                </Vertpad>
                <Vertpad>
                    <Flexdivider>
                        <div>
                            <Headerimage something="2"/>
                            
                            <Subheader>Softwarematige dataverwijdering</Subheader>
                            <Paragraph>
                            Softwarematige vernietiging van harde schijven wordt uitgevoerd d.m.v. gecertificeerde software. Door binaire dataoverschrijving o.b.v. de norm 5220.22M worden de gegevens permanent gewist. Gegevensdragers welke niet 100% succesvol kunnen worden gewist vernietigen wij alsnog op mechanische wijze.
                            </Paragraph>
                        </div>
                        <div>
                        <Headerimage something="3"/>
                            <Subheader>Mechanische datavernietiging</Subheader>
                            <Paragraph>
                            Het mechanisch vernietigen van gegevensdragers zoals harde schijven, SSD’s, USB’s, magneetbanden, tapes en diskettes wordt discreet uitgevoerd in ons beveiligd verwerkingsdepot. Voor de mechanische vernietiging van gegevensdragers wordt een DIN 66399 gecertificeerde schredder ingezet.
                            </Paragraph>
                        </div>

                    </Flexdivider>
                    
                </Vertpad>
                <Vertpad>
                <Subheader>Wat zijn de voordelen van dataverwijdering door Green Digital?</Subheader>
                    <Checkgrid>
                        <Griditem><Icoon icon={faCheck}/><p><b>Afvoer en transport </b><br/>Vanuit veiligheidsoogpunt vernietigen wij uitsluitend op onze beveiligde afgesloten vernietigingslocatie. In overleg kunnen wij de gegevensdragers, eventueel met bijbehorende smartphones, computers, laptops of tablets, bij u afhalen en zorgdragen voor het transport. De gegevensdragers worden verpakt in afgesloten kisten of security containers. Bij kleinere hoeveelheden adviseren wij gebruik te maken van een beveiligde pakketdienst. Voor het vernietigingsproces maakt dit geen verschil, uw pakket wordt na ontvangst geregistreerd, beveiligd opgeslagen en vernietigd. Wij kunnen de gegevensdragers bij u afhalen, maar u kunt deze ook bij ons afleveren.</p></Griditem>
                        <Griditem><Icoon icon={faCheck}/><p><b>Opkoop bijbehorende hardware </b><br/>Indien u bijbehorende apparatuur zoals mobiele telefoons, desktop computers, servers of laptops ook wilt laten afvoeren, dan kunnen wij dat voor u verzorgen. Mobiele telefoons en ICT apparatuur, niet ouder dan ca vier jaar, vertegenwoordigd vaak een interessante restwaarde die uw organisatie kan ontvangen of verrekend kan worden met eventuele kosten voor de datavernietiging. Door middel van revisie zorgen wij ervoor dat de apparatuur weer kan worden hergebruikt en restmaterialen worden milieuvriendelijk gerecycled.</p></Griditem>
                        <Griditem><Icoon icon={faCheck}/><p><b>Datavernietiging AVG/GDPR Proof </b><br/>Het vernietigen van persoonsgegevens moet ten allen tijde op een vertrouwelijke manier conform de AVG worden uitgevoerd. Het recht op vergetelheid vereist van een organisatie dat persoonsgegevens veilig en onherstelbaar moeten worden vernietigd. Wij kunnen digitale gegevensdragers gecertificeerd en discreet vernietigen, inclusief de registratie van de serienummers met rapportage en certificaat.</p></Griditem>
                        <Griditem><Icoon icon={faCheck}/><p><b>Veilige verwerking </b><br/>Na het transport worden uw gegevensdragers in onze verwerkingslocatie opgeslagen en geregistreerd. Onze verwerkingslocatie is beveiligd met toegangsloten en alarmbeveiliging met opvolging via een meldkamer. Al onze medewerkers hebben een geheimhoudingsverklaring getekend. Slechts enkele geautoriseerde medewerkers hebben toegang tot de ruimte waar gegevensdragers worden opgeslagen en vernietigd.</p></Griditem>
                        <Griditem><Icoon icon={faCheck}/><p><b>Advies en passende oplossing </b><br/>Het boordelen van uw gegevens en gegevensdragers op mate van “vertrouwelijkheid” en het kiezen van de bijbehorende vernietigingsmethode, is maatwerk. Wij gaan daarom graag vooraf met u in gesprek om gezamenlijk tot een passende keuze te komen.</p></Griditem>
                        <Griditem><Icoon icon={faCheck}/><p><b>Frequente afvoer en datavernietiging </b><br/>Heeft u regelmatig gegevensdragers die moeten worden vernietigd ? Dan bieden wij u de mogelijkheid om o.b.v. een contract en een afvoerschema, het transport en de vernietiging van uw gegevensdragers te verzorgen.</p></Griditem>
                        <Griditem><Icoon icon={faCheck}/><p><b>Vernietigingscertificaat </b><br/>Als bewijs van succesvolle vernietiging verstrekken wij een vernietigingscertificaat. Hiermee heeft u de garantie dat de datavernietiging volgens de Europese richtlijnen is uitgevoerd. Alle gegevensdragers worden geregistreerd en met het serienummer vermeld op het certificaat of rapport. Het certificaat is uw garantiebewijs bij een audit of controle.</p></Griditem>
                    </Checkgrid>
                </Vertpad>
                <Vertpad>
                    <Subheader>Datavernietiging, hoe werkt dat?</Subheader>
                    <Paragraph>
                   Onze werkwijze bestaat uit verschillende fasen:
                    </Paragraph>
                    <Stappen2 stappenplan={Stappenplan} />
                </Vertpad>
            </Containerpad>

        </Maincontent>
    <Padding/>
  </Layout>
}

export default Datavernietiging


